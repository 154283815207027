import {
  addMembersGroup,
  createGroup,
  deleteGroup,
  getGroup,
  getUsers,
  removeMembersGroup,
  updateGroup,
} from 'utils/apollo';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { createMatchSelector, goBack, push } from 'connected-react-router';
import { group as groupActions, saveGroup as saveGroupActions, users as usersActions } from './actions';

import ActionTypes from './constants';
import { message } from 'antd';
import messages from './messages';
import { selectBuyer } from 'containers/MainLayout/selectors';
import { selectGroup } from './selectors';
import translations from 'translations';
import utilsMessages from 'utils/messages';

function* initDataFlow() {
  yield put(groupActions.request());
  yield put(usersActions.request());
}

function* loadUsers() {
  while (true) {
    yield take(ActionTypes.FETCH_USERS_REQUEST);
    const response = yield call(getUsers);
    if (!response.errors) {
      yield put(usersActions.success(response));
    }
  }
}

function* loadGroup() {
  while (true) {
    yield take(ActionTypes.FETCH_GROUP_REQUEST);
    const route = yield select(createMatchSelector('/groups/:slug'));
    if (route.params.slug === '0') {
      yield put(
        groupActions.success({
          name: '',
          members: [],
          permissions: [],
        }),
      );
    } else {
      const response = yield call(getGroup, {
        id: route.params.slug,
      });
      if (response.errors) {
        yield put(groupActions.failure(response.errors));
        message.error(translations(messages.loadGroupFailed));
        yield put(push('/group-management'));
      } else {
        yield put(
          groupActions.success({
            ...response,
            members: response.members,
          }),
        );
      }
    }
  }
}

function* deleteGroupFlow() {
  while (true) {
    yield take(ActionTypes.DELETE_GROUP_REQUEST);
    const route = yield select(createMatchSelector('/groups/:slug'));
    const response = yield call(deleteGroup, {
      input: { groupId: route.params.slug },
    });
    if (!response.errors) {
      message.success(translations(utilsMessages.deleteSuccess));
      yield put(goBack());
    } else {
      message.error(translations(utilsMessages.deleteFailed));
    }
  }
}

function* saveGroupFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SAVE_GROUP_REQUEST);
    const route = yield select(createMatchSelector('/groups/:slug'));
    const buyer = yield select(selectBuyer());

    if (route.params.slug === '0') {
      const response = yield call(createGroup, {
        input: {
          name: payload.name,
          memberIds: payload.members,
          permissions: payload.permissions.map(p => ({
            action: p,
            scope: {
              type: 'BUYER',
              id: buyer.id,
            },
          })),
        },
      });
      if (!response.errors) {
        yield put(saveGroupActions.success(response.userGroup));
        message.success(translations(utilsMessages.saveSuccess));
        yield put(goBack());
      } else {
        message.error(translations(utilsMessages.saveFailed));
      }
    } else {
      const group = yield select(selectGroup());
      const removedUsers: string[] = group.members.map(u => u.id);

      yield call(removeMembersGroup, {
        input: {
          groupId: route.params.slug,
          memberIds: removedUsers,
        },
      });
      yield call(addMembersGroup, {
        input: {
          groupId: route.params.slug,
          memberIds: payload.members,
        },
      });
      if (group.isSystemCreated) {
        message.success(translations(utilsMessages.saveSuccess));
        yield put(goBack());
      } else {
        const response = yield call(updateGroup, {
          input: {
            groupId: route.params.slug,
            name: payload.name,
            permissions: payload.permissions.map(p => ({
              action: p,
              scope: {
                type: 'BUYER',
                id: buyer.id,
              },
            })),
          },
        });
        if (!response.errors) {
          yield put(saveGroupActions.success(response.userGroup));
          message.success(translations(utilsMessages.saveSuccess));
          yield put(goBack());
        } else {
          message.error(translations(utilsMessages.saveFailed));
        }
      }
    }
  }
}

// Individual exports for testing
export default function* groupDetailsPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initDataFlow);
  yield fork(loadGroup);
  yield fork(loadUsers);
  yield fork(deleteGroupFlow);
  yield fork(saveGroupFlow);
}
