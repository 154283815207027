/*
 *
 * GroupDetailsPage reducer
 *
 */
import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  group: {
    loading: true,
  },
  users: {
    data: [],
    loading: true,
  },
};

function groupsPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.FETCH_GROUP_SUCCESS:
      return {
        ...state,
        group: {
          ...action.payload,
        },
      };
    case ActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: {
          data: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default groupsPageReducer;
