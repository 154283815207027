/*
 *
 * GroupDetailsPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/GroupDetailsPage/DEFAULT_ACTION',
  FETCH_GROUP_REQUEST = 'app/GroupDetailsPage/FETCH_GROUP_REQUEST',
  FETCH_GROUP_SUCCESS = 'app/GroupDetailsPage/FETCH_GROUP_SUCCESS',
  FETCH_GROUP_FAILURE = 'app/GroupDetailsPage/FETCH_GROUP_FAILURE',
  FETCH_USERS_REQUEST = 'app/GroupDetailsPage/FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS = 'app/GroupDetailsPage/FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE = 'app/GroupDetailsPage/FETCH_USERS_FAILURE',
  DELETE_GROUP_REQUEST = 'app/GroupDetailsPage/DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS = 'app/GroupDetailsPage/DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE = 'app/GroupDetailsPage/DELETE_GROUP_FAILURE',
  SAVE_GROUP_REQUEST = 'app/GroupDetailsPage/SAVE_GROUP_REQUEST',
  SAVE_GROUP_SUCCESS = 'app/GroupDetailsPage/SAVE_GROUP_SUCCESS',
  SAVE_GROUP_FAILURE = 'app/GroupDetailsPage/SAVE_GROUP_FAILURE',
}

export default ActionTypes;
