/*
 * GroupDetailsPage Messages
 *
 * This contains all the text for the GroupDetailsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.GroupDetailsPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Chi tiết nhóm',
  },
  createGroup: {
    id: `${scope}.createGroup`,
    defaultMessage: 'Tạo nhóm',
  },
  permissions: {
    id: `${scope}.permissions`,
    defaultMessage: 'Quyền',
  },
  groupName: {
    id: `${scope}.groupName`,
    defaultMessage: 'Tên nhóm',
  },
  addMember: {
    id: `${scope}.addMember`,
    defaultMessage: 'Thêm đồng nghiệp',
  },
  enterGroupName: {
    id: `${scope}.enterGroupName`,
    defaultMessage: 'Điền tên nhóm',
  },
  selectMember: {
    id: `${scope}.selectMember`,
    defaultMessage: 'Chọn đồng nghiệp',
  },
  loadGroupFailed: {
    id: `${scope}.loadGroupFailed`,
    defaultMessage: 'Không tìm thấy nhóm',
  },
  groupNameRequired: {
    id: `${scope}.groupNameRequired`,
    defaultMessage: 'Bạn phải nhập tên nhóm',
  },
  backToList: {
    id: `${scope}.backToList`,
    defaultMessage: 'Quay lại danh sách',
  },
  removeGroup: {
    id: `${scope}.removeGroup`,
    defaultMessage: 'Xoá nhóm',
  },
  removeGroupMessage: {
    id: `${scope}.removeGroupMessage`,
    defaultMessage: 'Bạn chắc chắn muốn xoá nhóm này !',
  },
  groupNameDuplicate: {
    id: `${scope}.groupNameDuplicate`,
    defaultMessage: 'Tên nhóm đã tồn tại!',
  },
});
