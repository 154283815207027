import { ApplicationRootState } from 'types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the groupDetailsPage state domain
 */

const selectGroupDetailsPageDomain = (state: ApplicationRootState) => {
  return state ? state.groupDetailsPage : initialState;
};

const selectGroup = () =>
  createSelector(selectGroupDetailsPageDomain, substate => {
    return (substate as any).group;
  });

/**
 * Other specific selectors
 */

/**
 * Default selector used by GroupDetailsPage
 */

const selectGroupDetailsPage = () =>
  createSelector(selectGroupDetailsPageDomain, substate => {
    return substate;
  });

export default selectGroupDetailsPage;
export { selectGroupDetailsPageDomain, selectGroup };
