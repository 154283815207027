/**
 *
 * User
 *
 */

import { Col, Input, Row, message } from 'antd';
import Form from 'components/Form/Form';
import { Box } from 'components/Box/Box';
import Button from 'components/TrackingComponents/Button';
import { FormattedMessage } from 'react-intl';
import Permissions from 'components/Permissions';
import React, { useState, useEffect } from 'react';
import Select from 'components/Select/Select';
import { Group, User } from './types';
import messages from './messages';
import styled from 'styles/styled-components';
import { uniq } from 'lodash';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import { convertPermissionsToPermissionGroups, PermissionGroup } from 'utils/permissionMap';

const Side = styled(Box)`
  margin-bottom: 18pt;
`;

const GroupHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Heading = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
`;

const Body = styled.div``;

interface OwnProps {
  userID: string;
  form: any;
  group: Group;
  users: User[];
  onSave: (data) => void;
  onDelete: () => void;
  goBack: () => void;
  checkGroupName: (name) => void;
  isCreateMode: boolean;
  groupList: any;
}

const Group: React.SFC<OwnProps> = (props: OwnProps) => {
  const { group, groupList, userID } = props;
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [groupName, setGroupName] = useState(group.name);
  const [selectedUsers, setSelectedUsers] = useState(group.members || []);
  const [selectedPermissions, setSelectedPermissions] = useState((group.permissions || []).map((p) => p.action));
  useEffect(() => {
    setGroupName(group.name);
    setSelectedPermissions((group.permissions || []).map((p) => p.action));
    setSelectedUsers(group.members || []);
  }, [group]);

  const onAddPermissions = (permissions) => {
    setSelectedPermissions([...selectedPermissions, ...permissions]);
  };
  const onRemovePermissions = (permissions) => {
    if (!permissions.length) {
      return;
    }
    const permissionGroups = convertPermissionsToPermissionGroups(selectedPermissions);

    const permissionGroupsResult: PermissionGroup[] = [];
    for (const permissionGroup of permissionGroups) {
      let permissionCounter = 0;
      for (const permission of permissionGroup.permissions) {
        if (permissions.indexOf(permission) > -1) {
          permissionCounter += 1;
        }
      }
      if (permissionCounter !== permissions.length) {
        permissionGroupsResult.push(permissionGroup);
      }
    }
    let permisisonsResult: string[] = [];
    for (const permissionGroup of permissionGroupsResult) {
      permisisonsResult = permisisonsResult.concat(permissionGroup.permissions);
    }

    setSelectedPermissions(uniq(permisisonsResult));
  };
  const onRemoveUser = (userId: string) => () => {
    setSelectedUsers(selectedUsers.filter((u) => u.id !== userId));
  };
  const onAddUser = (value: string) => {
    if (!selectedUsers.find((u) => u.id === value)) {
      const selectedUser = props.users.find((u) => u.id === value);
      if (selectedUser) {
        setSelectedUsers([...selectedUsers, selectedUser]);
      }
    }
  };
  const checkName = (form: any) => (rule: any, value: any, callback: any) => {
    const name = form.getFieldValue('name');
    const userGroup: string[] = groupList.map((item) => item.name);
    const isDuplicateName = userGroup.includes(name);
    callback(isDuplicateName ? translations(messages.groupNameDuplicate) : undefined);
    setIsDuplicate(isDuplicateName);
    setGroupName(name);
  };

  const onSave = () => {
    if (groupName) {
      if (isDuplicate) {
        message.error(translations(messages.groupNameDuplicate));
      } else {
        props.onSave({
          name: groupName,
          permissions: selectedPermissions,
          members: selectedUsers.map((u) => u.id),
        });
      }
    } else {
      message.error(translations(messages.groupNameRequired));
    }
  };

  return (
    <>
      <Row gutter={8}>
        <Col lg={7} xs={24}>
          <Side>
            <GroupHeading>
              <Heading>
                <FormattedMessage {...messages.groupName} />
              </Heading>
            </GroupHeading>
            {!group.isSystemCreated ? (
              <Form.Item colon={false} required={false} hasFeedback>
                {props.form.getFieldDecorator('name', {
                  rules: [
                    {
                      message: 'name',
                    },
                    { required: true, message: translations(messages.groupNameRequired) },
                    {
                      validator: checkName(props.form),
                    },
                  ],
                  initialValue: groupName,
                })(<Input style={{ width: '100%' }} placeholder={translations(messages.enterGroupName)} />)}
              </Form.Item>
            ) : (
              <p>{groupName}</p>
            )}
            <GroupHeading style={{ marginTop: 28 }}>
              <Heading>
                <FormattedMessage {...messages.addMember} />
              </Heading>
            </GroupHeading>
            <Select
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={undefined}
              items={props.users
                .filter((user) => !selectedUsers.find((su) => su.id === user.id))
                .map((user) => ({
                  value: user.id,
                  label: user.name || (user.email && user.email.split('@')[0]),
                }))}
              onChange={onAddUser}
              style={{ width: '100%' }}
              placeholder={translations(messages.selectMember)}
            />

            {selectedUsers.map((user) => (
              <Row key={user.id} style={{ paddingTop: 8, paddingBottom: 8 }} justify="space-between" align="middle">
                <span>{user.name || (user.email && user.email.split('@')[0])}</span>
                {user.id !== userID && (
                  <Button size="middle" onClick={onRemoveUser(user.id)}>
                    <FormattedMessage {...utilsMessages.remove} />
                  </Button>
                )}
              </Row>
            ))}
          </Side>
          {!props.isCreateMode && !group.isSystemCreated && (
            <Button
              trackingCategory="Group Details | Remove | Button"
              trackingAction="Remove Group"
              type="danger"
              style={{ marginRight: 20 }}
              onClick={props.onDelete}
              ghost
            >
              <FormattedMessage {...messages.removeGroup} />
            </Button>
          )}
        </Col>
        <Col lg={17} xs={24}>
          <Body>
            <Box>
              <GroupHeading>
                <Heading>
                  <FormattedMessage {...messages.permissions} />
                </Heading>
              </GroupHeading>
              <Permissions
                selectedPermissions={selectedPermissions}
                onAddPermissions={onAddPermissions}
                onRemovePermissions={onRemovePermissions}
                selectedGroupPermissions={[{}]}
                isSystemCreated={group.isSystemCreated}
              />
            </Box>
            <Row justify="end">
              <Button
                trackingCategory="Group Details | Cancel | Button"
                trackingAction="Back to list"
                style={{ marginRight: 20 }}
                onClick={props.goBack}
                size="large"
              >
                <FormattedMessage {...messages.backToList} />
              </Button>
              <Button
                trackingCategory="Group Details | Save | Button"
                trackingAction="Save"
                type="primary"
                onClick={onSave}
                size="large"
              >
                <FormattedMessage {...utilsMessages.save} />
              </Button>
            </Row>
          </Body>
        </Col>
      </Row>
    </>
  );
};

export default Form.create({ name: 'group' })(Group);
