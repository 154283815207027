/*
 *
 * GroupDetailsPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const group = createAsyncAction(
  ActionTypes.FETCH_GROUP_REQUEST,
  ActionTypes.FETCH_GROUP_SUCCESS,
  ActionTypes.FETCH_GROUP_FAILURE,
)<void, {}, Error>();

export const users = createAsyncAction(
  ActionTypes.FETCH_USERS_REQUEST,
  ActionTypes.FETCH_USERS_SUCCESS,
  ActionTypes.FETCH_USERS_FAILURE,
)<void, {}, Error>();

export const deleteGroup = createAsyncAction(
  ActionTypes.DELETE_GROUP_REQUEST,
  ActionTypes.DELETE_GROUP_SUCCESS,
  ActionTypes.DELETE_GROUP_FAILURE,
)<void, {}, Error>();

export const saveGroup = createAsyncAction(
  ActionTypes.SAVE_GROUP_REQUEST,
  ActionTypes.SAVE_GROUP_SUCCESS,
  ActionTypes.SAVE_GROUP_FAILURE,
)<{}, {}, Error>();
