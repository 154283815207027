/**
 *
 * GroupDetailsPage
 *
 */

import { ContainerState, RootState } from './types';
import { Dispatch, compose } from 'redux';
import React, { Fragment, PureComponent } from 'react';
import { deleteGroup as deleteGroupActions, saveGroup as saveGroupActions } from './actions';
import { selectUser, selectUserGroups } from 'containers/MainLayout/selectors';

import { FormattedMessage } from 'react-intl';
import Group from './Group';
import { Modal } from 'antd';
import PageHeader from 'components/PageHeader/PageHeader';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { goBack } from 'connected-react-router';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectGroupDetailsPage from './selectors';
import translations from 'translations';
import utilsMessages from 'utils/messages';

interface OwnProps {
  user: any;
  groupdetailspage: ContainerState;
  match: any;
  groupList: any;
}

interface StateProps {}

interface DispatchProps {
  goBack: () => void;
  save: (data) => void;
  remove: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class GroupDetailsPage extends PureComponent<Props> {
  private onDeleteGroup = () => {
    const { remove } = this.props;
    Modal.confirm({
      autoFocusButton: null,
      okButtonProps: {
        danger: true,
      },
      title: translations(messages.removeGroupMessage),
      okText: translations(utilsMessages.yes),
      cancelText: translations(utilsMessages.no),
      onOk: () => {
        remove();
      },
    });
  };

  public render() {
    const { match, groupList, user } = this.props;
    const isCreateMode = match.params.slug === '0';
    return (
      <Fragment>
        <PageHeader>
          {isCreateMode ? <FormattedMessage {...messages.createGroup} /> : <FormattedMessage {...messages.header} />}
        </PageHeader>
        <Group
          group={this.props.groupdetailspage.group}
          users={this.props.groupdetailspage.users.data}
          userID={user.id}
          onSave={this.props.save}
          isCreateMode={isCreateMode}
          goBack={this.props.goBack}
          onDelete={this.onDeleteGroup}
          groupList={groupList}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  groupdetailspage: selectGroupDetailsPage(),
  groupList: selectUserGroups(),
  user: selectUser(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    goBack: () => dispatch(goBack()),
    save: (data) => dispatch(saveGroupActions.request(data)),
    remove: () => dispatch(deleteGroupActions.request()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({ key: 'groupDetailsPage', reducer: reducer });
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'groupDetailsPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(GroupDetailsPage);
